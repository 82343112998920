<template>
    <div>
      <div class="row justify-content-between align-items-center w-100 my-4 mx-6">
        <h4 class="">Özellikler</h4>
              <button class="btn btn-primary text-white mx-6" @click="addFileModal = true" >
                <p style="font-size: 13px" class="text-white" >
                  <i class="flaticon2-plus mb-1"></i> Yeni Özellik Ekle
                </p>
              </button>
      </div>
      <div class="row justify-content-end">
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-0" />
        </div>
        <hr class="w-100 mx-4" />
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Özellik Kategorileri Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.email="{ item }">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:item.isActive="{ item }">
          <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end w-100">
            <button @click="updateFeature(item)">
            <i class="flaticon-eye icon-lg"></i>
          </button>
          <button class="border-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md ml-4"></i>
          </button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
      
      <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4>Kategori Bilgileri</h4>
        </template>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <label for="">Özellik Adı</label>
              <input type="text" class="form-control" v-model="addCategoryText" />
            </div>
            <div class="col-12 mt-4">
              <MultiSelect
              :key="categories.length"
              v-model="category"
              :options="categories"
              :defaultValue="category ? category : '' "
              :multiple="false"
              label="Kategori"
              placeholder="Kategori Seçiniz"
              id="kategori"
            />
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal = false">
              Vazgeç
            </b-button>
            <b-button variant="success" class="ml-4" @click="addFeature"> Kaydet </b-button>
          </div>
        </template>
      </b-modal>
      <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteFeature"
    />
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FEATURES,FEATURES_CATEGORY } from "@/core/services/store/features/features.module";
import { mapGetters } from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      addCategoryText : "",
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "feature_name" },
        {text:"Kategori",value:"category_name"},
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      categories:[],
      category:'',
      updateModal : 0,
      feature_id : ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Özellik Kategorileri", route: "/manage/feature" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

    
      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `&Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      let payload = {myUser:this.myUser,query:query}
      this.$store.dispatch(FEATURES,payload).then((response)=>{
        this.items = response
        this.totalItems = 3;

        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(response.length / this.perPage),
          total_items: response.length,
        };
        this.loading = false;
      })
      

     
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async addFeature(){
      const data = {
        user_token:this.myUser,
        feature_name:this.addCategoryText,
        category:this.category.value
      }
      if(!this.updateModal){
        await this.$ApiService
          .post('admin/features', data)
          .then(( res ) => {
            console.log(res)
            if (res.data.status.code === 200) {
            this.getList()
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            );
            this.addFileModal = false
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
          })
          .catch((err) => {
            this.$generateNotification(this, "error", "Hata");
          });
      }else{
        data["features_id"] = this.feature_id
        const formData = new FormData()
        for(const key in data){
          formData.append(key,data[key])
        }
        await this.$ApiService
          .put('admin/features', formData)
          .then(( res ) => {
            this.addCategoryText = ""
            this.updateModal = false
            this.feature_id = ""
            this.category = ''
            if (res.data.status.code === 200) {
            this.getList()
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            );
            this.addFileModal = false
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
          })
          .catch((err) => {
            this.$generateNotification(this, "error", "Hata");
          });
      }
      

    },
    setCategorySelectValues(categories) {
      const setSelectCategories = categories.map((index, key) => {
        return { value: index._id.$oid, text: index.category_name };
      });
      return setSelectCategories;
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `admin/features`;
      this.deleteIds = `?user_token=${this.myUser}&features_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeleteFeature');
    },
    updateFeature(item){
      this.updateModal =true
      this.addFileModal = true
      this.feature_id = item._id.$oid
      const selectedCategory = this.getFeaturesCategory.find(category => category._id.$oid === item.category)
      this.category = {text:selectedCategory.category_name,value:selectedCategory._id.$oid}
      this.addCategoryText = item.feature_name
    }

    
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    addFileModal(){
      if(this.addFileModal){
        if(this.getFeaturesCategory.length > 0){
          this.categories = this.setCategorySelectValues(this.getFeaturesCategory)
        }else{
          const payload = {myUser : this.myUser,query:""}
          this.$store.dispatch(FEATURES_CATEGORY,payload).then((response)=>{
            this.categories = this.setCategorySelectValues(response)
          })
        }
      }
    }
  },
  components: {
    InsertUpdateOperations: () =>
      import("@/components/systemUser/modals/InsertUpdateOperations"),
    DetailView: () => import("@/components/systemUser/modals/DetailView"),
  },
  computed:{
    ...mapGetters([
      "myUser",
      "getFeaturesCategory"
    ]),
  }
};
</script>
